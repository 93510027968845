<template>
  <section class="all-cases-view" v-loading="loading">
    <div class="container-default">
      <div class="listings-navbar">
        <el-row>
          <el-col :lg="11">
            <ul class="d-flex list-items-group-15 barcode-print">
              <li>
                <div class="form-element input-with-icon search-input-lg">
                  <el-input
                    placeholder="Accession / Patient Name"
                    v-model="search_string"
                    @input="getAutoCompleteCases()"
                    :clearable="true"
                  ></el-input>
                  <span>
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid icon"
                    />
                  </span>
                </div>
              </li>
              <li v-if="selectedCaseIds.length">
                <el-dropdown
                  class="bulk-assign-btn"
                  @command="assignBillingUser"
                  @visible-change="handleDropdownVisibleChange"
                >
                  <span class="generate-report cursor"> Bulk Assigne </span>
                  <template #dropdown>
                    <el-dropdown-menu
                      placement="bottom"
                      class="status-dropdown-menu actionDropdown"
                    >
                    <div  class="form-element input-with-icon">
                      <el-input
                        placeholder="Search"
                        v-model="searchQuery"
                        @input="filterUsers"
                        class="dropdown-search"
                      />
                      <span>
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid icon"
                    />
                  </span>
                    </div>
              <div class="dropdown-list">
                <el-dropdown-item
                        v-for="user in filteredBillingUsers"
                        :key="user._id"
                        :command="{
                          userId: user._id,
                        }"
                      >
                        {{ getOptionLabel(user) }}</el-dropdown-item
                      >
              </div>
                  
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </li>
            </ul>
          </el-col>
          <el-col :lg="13">
            <ul
              class="d-flex flex-wrap justify-content-end list-items-group-15"
            >
              <li>
                <div class="form-element search-with-filter-input-without-icon">
                  <el-select
                    v-model="billing_assigne"
                    placeholder="Select Assigne"
                    @change="handleCurrentChange(1)"
                    :loading="userLoading"
                    :reserve-keyword="false"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="(user, index) in billingUsers"
                      :key="index"
                      :label="getOptionLabel(user)"
                      :value="user._id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </li>
              <li>
                <div class="form-element search-with-filter-input-without-icon">
                  <el-select
                    v-model="searchBy"
                    clearable
                    filterable
                    default-first-option
                    :reserve-keyword="false"
                    placeholder="Select Search"
                    @change="clearInputSearch"
                  >
                    <el-option
                      v-for="item in searchOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </li>

              <li v-if="searchBy == 'Patient DOB'">
                <div class="search-with-date-input">
                  <el-date-picker
                    format="MM-DD-YYYY"
                    v-model="date_of_birth"
                    :disabled-date="disabledDate"
                    @change="fetchPendingBilledCases()"
                    placeholder="Select DOB"
                    :clearable="true"
                  ></el-date-picker>
                </div>
              </li>
              <li v-else-if="searchBy == 'emr'">
                <div class="form-element input-with-icon search-input-lg">
                  <el-input
                    placeholder="Search EMR NO"
                    v-model="emr"
                    @input="getAutoCompleteCases()"
                    :clearable="true"
                  ></el-input>
                  <span>
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid icon"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'patient_name'">
                <div class="form-element input-with-icon search-input-lg">
                  <el-input
                    placeholder="Search Patient"
                    v-model="patient_name"
                    @input="getAutoCompleteCases()"
                    :clearable="true"
                  ></el-input>
                  <span>
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid icon"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'accession_id'">
                <div class="form-element input-with-icon search-input-lg">
                  <el-input
                    placeholder="Search Accession ID"
                    v-model="accession_id"
                    @input="getAutoCompleteCases()"
                    :clearable="true"
                  ></el-input>
                  <span>
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid icon"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'test_performed_by'">
                <div class="form-element search-with-filter-input">
                  <el-select
                    v-model="test_performed_by"
                    placeholder="Select User"
                    default-first-option
                    :reserve-keyword="false"
                    filterable
                    clearable
                    @change="handleCurrentChange(1)"
                    @focus="fetchAllUsers"
                    :loading="userLoading"
                  >
                    <el-option
                      v-for="(user, index) in usersList"
                      :key="index"
                      :label="user.first_name + ' ' + user.last_name"
                      :value="user._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'hospital_marketers'">
                <div class="form-element search-with-filter-input">
                  <el-select
                    v-model="hospital_marketer"
                    placeholder="Select Territory Manager"
                    default-first-option
                    :reserve-keyword="false"
                    filterable
                    clearable
                    @change="handleCurrentChange(1)"
                    @focus="fetchAllUsers"
                    :loading="userLoading"
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="(user, index) in marketerData"
                      :key="index"
                      :label="user.first_name + ' ' + user.last_name"
                      :value="user._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'hospital'">
                <div class="form-element search-with-filter-input">
                  <el-select
                    v-model="hospital"
                    placeholder="Select Ordering Facility"
                    default-first-option
                    :reserve-keyword="false"
                    filterable
                    clearable
                    @change="handleCurrentChange(1)"
                    @focus="fetchAllHospitals"
                    :loading="hospitalLoading"
                  >
                    <el-option
                      v-for="hospital in hospitalsData.data"
                      :key="hospital"
                      :label="hospital.name"
                      :value="hospital._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'ordering_physician'">
                <div class="form-element search-with-filter-input">
                  <el-select
                    v-model="physician"
                    placeholder="Select"
                    default-first-option
                    :reserve-keyword="false"
                    filterable
                    clearable
                    @change="handleCurrentChange(1)"
                    @focus="fetchAllPhysicians"
                    :loading="physicianLoading"
                  >
                    <el-option
                      v-for="physician in physiciansData.data"
                      :key="physician"
                      :label="physician.name"
                      :value="physician._id"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li v-else-if="searchBy == 'case_types'">
                <div
                  class="form-element search-with-filter-input"
                  v-if="
                    getAuthenticatedUser.user_type == 'LAB_TECHNOLOGIST' &&
                    getAuthenticatedUser.allowed_case_types
                  "
                >
                  <el-select
                    v-model="caseType"
                    placeholder="Select"
                    filterable
                    clearable
                    default-first-option
                    :reserve-keyword="false"
                    @change="handleCurrentChangeCaseType(1)"
                  >
                    <el-option
                      v-for="caseType in getUserBasedCaseTypes(caseTypes)"
                      :key="caseType"
                      :label="caseType.label"
                      :value="caseType.value"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
                <div class="form-element search-with-filter-input" v-else>
                  <el-select
                    v-model="caseType"
                    placeholder="Select"
                    filterable
                    clearable
                    default-first-option
                    @clear="clearTest"
                    :reserve-keyword="false"
                    @change="handleCurrentChangeCaseType(1)"
                  >
                    <el-option
                      v-for="caseType in getLabBasedCaseTypes(caseTypes)"
                      :key="caseType"
                      :label="caseType.label"
                      :value="caseType.value"
                    >
                    </el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
              <li v-if="caseType && caseType.length">
                <div
                  v-if="
                    caseType && caseType.length && caseType === 'TOXICOLOGY'
                  "
                  class="form-element search-with-filter-input trigger-drowpdown"
                >
                  <el-select
                    v-model="test_info"
                    placeholder="Select Test Id"
                    filterable
                    clearable
                    multiple
                    collapse-tags
                    default-first-option
                    :reserve-keyword="false"
                    @change="handleTest(1)"
                  >
                    <el-option
                      v-for="test in this.testIds"
                      :key="test"
                      :label="test.category"
                      :value="test._id"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="form-element search-with-filter-input trigger-drowpdown"
                  v-else
                >
                  <el-select
                    v-model="test_info"
                    placeholder="Select Test Id"
                    filterable
                    clearable
                    multiple
                    collapse-tags
                    default-first-option
                    :reserve-keyword="false"
                    @change="handleTest(1)"
                  >
                    <el-option
                      v-for="test in this.testIds"
                      :key="test"
                      :label="test.category"
                      :value="test._id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </li>

              <li v-if="hasPermission('cases', 'create')">
                <el-button
                  class="btn button-with-icon"
                  @click="onReportsExport"
                >
                  <span class="mr-8">
                    <img
                      src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                      alt="icon"
                      class="inactive"
                    />
                    <img
                      src="@/assets/images/icons/export-icon-white.svg"
                      alt="icon"
                      class="active"
                    />
                  </span>
                  <span>Export</span>
                </el-button>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="primary-content-view-new">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table
            :data="getAllBilledCases.data"
            height="calc(100vh - 200px)"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            @sort-change="onChangeSort"
            @selection-change="handleSelectionChange($event)"
          >
            <el-table-column
              type="selection"
              width="40"
              class="selection-check"
            ></el-table-column>
            <el-table-column
              fixed="left"
              prop="accession_id"
              label="ACCESSION ID"
              min-width="120"
              sortable="custom"
            >
              <template #default="scope">
                <div class="name-accession">
                  <span id="accession-id" @click="goToCaseView(scope.row._id)">
                    {{ scope.row.accession_id }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Case Type"
              prop="case_types"
              min-width="125px"
              sortable="custom"
              fixed="left"
            >
              <template #default="scope">
                <div
                  class="case-type"
                  v-if="scope.row.case_types && scope.row.case_types.length > 1"
                >
                  <el-dropdown>
                    <span class="case-data-type">
                      {{ getCaseTypes(scope.row.case_types)[0] }}

                      <img
                        src="@/assets/images/case-type-arrow.svg"
                        alt="icon"
                      />
                    </span>

                    <template #dropdown>
                      <el-dropdown-menu class="case-type-dropdown">
                        <el-dropdown-item
                          class="cases-view"
                          v-for="(caseType, index) of getCaseTypes(
                            scope.row.case_types
                          )"
                          :key="index"
                        >
                          {{ caseType }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div
                  v-else-if="
                    scope.row.case_types && scope.row.case_types.length == 1
                  "
                  class="case-type"
                >
                  <span class="case-data-type">
                    {{ getCaseTypes(scope.row.case_types)[0] }}
                  </span>
                </div>
                <div v-else></div>
              </template>
            </el-table-column>
            <el-table-column
              prop="patient_info.first_name"
              label="PATIENT NAME"
              min-width="140"
              sortable="custom"
              fixed="left"
            >
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="getPatientNameByInfo(scope.row.patient_info)"
                  @click="
                    copyTitle(getPatientNameByInfo(scope.row.patient_info))
                  "
                >
                  <span class="text-center ordering_facility-value">{{
                    getPatientNameByInfo(scope.row.patient_info)
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="Patient DOB" width="100">
              <template #default="scope">
                <span v-if="scope.row.patient_info">
                  {{
                    scope.row.patient_info.date_of_birth
                      ? formatDate(scope.row.patient_info.date_of_birth)
                      : ""
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="ordering_facility"
              label="ORDERING FACILITIES"
              min-width="215"
              class="ordering_facility"
            >
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="scope.row.hospital ? scope.row.hospital.name : '--'"
                >
                  <span class="text-center ordering_facility-value">{{
                    scope.row.hospital ? scope.row.hospital.name : "--"
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="Physician" min-width="120">
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="
                    scope.row.ordering_physician
                      ? scope.row.ordering_physician.name.includes('Dr')
                        ? scope.row.ordering_physician.name
                        : 'Dr ' + scope.row.ordering_physician.name
                      : '--'
                  "
                >
                  <span class="ordering_physician-value">
                    {{
                      scope.row.ordering_physician
                        ? scope.row.ordering_physician.name.includes("Dr")
                          ? scope.row.ordering_physician.name
                          : "Dr " + scope.row.ordering_physician.name
                        : "--"
                    }}
                  </span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="Collected On"
              min-width="135"
              prop="collection_date"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.collection_date" class="d-flex">
                  <div class="date pr-10">
                    {{
                      momentWithTimezone(
                        scope.row.collection_date,
                        "MM-DD-yyyy"
                      )
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Received On"
              min-width="125"
              prop="received_date"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.received_date" class="d-flex">
                  <div class="date pr-10">
                    {{
                      momentWithTimezone(scope.row.received_date, "MM-DD-yyyy")
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              width="130"
              sortable="custom"
              prop="status"
            >
              <template v-slot="scope">
                <div class="column-status cursor" v-if="scope.row.status">
                  <el-dropdown
                    class="status-dropdown"
                    v-if="hasPermission('cases', 'update_status')"
                  >
                    <span
                      class="el-dropdown-link"
                      v-if="scope.row.status && scope.row.status == 'COMPLETE'"
                    >
                      <el-tag :type="getStatusType(scope.row.status)">
                        FINALIZED
                      </el-tag>
                    </span>
                    <span class="el-dropdown-link" v-else>
                      <el-tag :type="getStatusType(scope.row.status)">{{
                        scope.row.status.split("_").join(" ")
                      }}</el-tag>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu class="status-dropdown-menu">
                        <el-dropdown-item
                          command="TEST_REPORTS"
                          :disabled="scope.row.status === 'TEST_REPORTS'"
                          >PROCESSING</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="COMPLETE"
                          :disabled="scope.row.status === 'COMPLETE'"
                          >FINALIZED</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>

                  <el-tag
                    v-else-if="isFacilityUser"
                    :type="getStatusType(scope.row.status)"
                    >{{
                      scope.row.status === "COMPLETE"
                        ? "FINALIZED"
                        : "PROCESSING"
                    }}</el-tag
                  >

                  <el-tag v-else :type="getStatusType(scope.row.status)">{{
                    scope.row.status.split("_").join(" ")
                  }}</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Reported On"
              width="120"
              prop="reported_at"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.reported_at" class="d-flex">
                  <div class="date pr-10">
                    {{
                      momentWithTimezone(scope.row.reported_at, "MM-DD-yyyy")
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Billing Representative" width="170">
              <template #default="scope">
                <div v-if="scope.row.billing_representative">
                  {{ getSelectedUser(scope.row.billing_representative) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Actions"
              width="180"
              fixed="right"
            >
              <template #default="scope">
                <ul class="listings__action-buttons">
                  <li>
                    <el-dropdown
                      @command="handleBillingAssigne"
                      trigger="click"
                    >
                      <span class="assigneBtn cursor"> Assigne </span>
                      <template #dropdown>
                        <el-dropdown-menu
                          placement="bottom"
                          class="status-dropdown-menu actionDropdown"
                        >
                          <el-dropdown-item
                            v-for="user in billingUsers"
                            :key="user._id"
                            :command="{
                              userId: user._id,
                              rowId: scope.row._id,
                            }"
                            :disabled="
                              user._id === scope.row.billing_representative
                            "
                          >
                            {{ getOptionLabel(user) }}</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </li>
                  <li @click="goToCaseView(scope.row._id)">
                    <el-button class="view-button" title="View Case">
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      scope.row.status != 'ARCHIVED' &&
                      scope.row.status == 'COMPLETE'
                    "
                  >
                    <el-button
                      title="Test Reports"
                      class="reports-button"
                      @click="onViewReports(scope.row)"
                    >
                      <img
                        v-if="
                          checkNewReport(scope.row) &&
                          checkCaseStatus(scope.row)
                        "
                        class="action-active"
                        src="@/assets/images/icons/actions/pdf-icon-new-active.svg"
                        alt="icon"
                      />

                      <img
                        v-else
                        class="action-active"
                        src="@/assets/images/icons/actions/pdf-icon.svg"
                        alt="icon"
                      />
                      <img
                        v-if="
                          checkNewReport(scope.row) &&
                          checkCaseStatus(scope.row)
                        "
                        class="action-inactive"
                        src="@/assets/images/icons/actions/pdf-icon-new-active.svg"
                        alt="icon"
                      />
                      <img
                        v-else
                        class="action-inactive"
                        src="@/assets/images/icons/actions/pdf-icon-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="listings listings__pagination">
          <el-pagination
            v-model:currentPage="page"
            v-model:page-size="pageSize"
            :page-sizes="[25, 50, 75, 100]"
            layout=" prev, pager, next, jumper, sizes ,total"
            :total="getAllBilledCases.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <ExportCases
      v-if="exportReportsDialogVisisble"
      v-on:close="exportReportsDialogVisisble = false"
      :selected_dob="date_of_birth"
      :selected_case_type="caseType"
      :selected_hospital="hospital"
      :selected_user="test_performed_by"
      :selected_marketer="hospital_marketer"
      :selected_tests="test_info"
      :selected_physician="physician"
      :selected_string="search_string"
      :selected_emr="emr"
      :selected_accession_id="accession_id"
      :search_billed_status="Unbilled"
      :search_case_statuses="'COMPLETE'"
      :search_first_name="patient_name"
      :pregnancy="pregnant"
      :selected_billing_assigne="billing_assigne"
    >
    </ExportCases>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { defineAsyncComponent } from "vue";
import caseConstants from "@/config/constants/caseConstants";
import { casesExtraSearchOptions } from "@/config/constants/searchConstants";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import caseDataHelper from "@/mixins/caseDataHelper";
import FilterHelper from "@/mixins/FilterHelper";
import AppHelper from "@/mixins/AppHelper";
const ExportCases = defineAsyncComponent(() =>
  import("@/components/cases/ExportCases")
);
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  components: {
    ExportCases,
  },
  created() {
    if (this.isFacilityUser) {
      let markerFilterIndex = this.searchOptions.findIndex(
        (option) => option.value == "hospital_marketers"
      );
      if (markerFilterIndex > -1) {
        this.searchOptions[markerFilterIndex].active = false;
      }
    }
    this.searchOptions = this.searchOptions.filter((option) => option.active);
  },
  computed: {
    ...mapGetters("cases", [
      "getTestReportsDownloadLog",
      "getTestReportsViewLog",
      "getAllBilledCases",
      "getBillingAssignStatus",
      "getBulkBillingAssignStatus",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("userAccounts", ["getAllUsers"]),
    ...mapGetters("labTests", ["getAllLabTests"]),
    ...mapGetters("profile", ["getProfileData"]),
    checkCaseStatus() {
      return (caseData) => {
        if (caseData.status === "COMPLETE") {
          return true;
        } else {
          return false;
        }
      };
    },
    checkNewReport() {
      return (caseData) => {
        if (caseData && caseData.test_reports && caseData.test_reports.length) {
          let newReport = caseData.test_reports.find((report) => {
            if (report.download_logs) {
              let userDownloadLog = report.download_logs.find(
                (e) => e.user_id === this.getAuthenticatedUser._id
              );
              if (userDownloadLog) {
                return false;
              } else {
                return true;
              }
            }
          });
          if (newReport) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };
    },
    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "default";
        } else if (status == "COMPLETE") {
          return "success";
        } else if (status == "TEST_REPORTS") {
          return "warning";
        } else if (status == "TEST_RESULTS") {
          return "info";
        } else if (status == "ARCHIVED") {
          return "danger";
        } else {
          return "";
        }
      };
    },
    getCovidStatusType() {
      return (statusVal) => {
        const status = statusVal;
        if (status == "POSITIVE") {
          return "danger";
        } else if (status == "NEGATIVE") {
          return "success";
        } else if (status == "INCONCLUSIVE") {
          return "info";
        } else {
          return "";
        }
      };
    },
    ...mapGetters("hospitals", ["getAllHospitals"]),
    ...mapGetters("physicians", ["getAllPhysicians"]),
    getCaseTypes() {
      return (caseTypes) => {
        return (caseTypes || []).map((e) => this.caseTypeMap[e]);
      };
    },
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  mixins: [FilterHelper, UserPermissionsHelper, AppHelper, caseDataHelper],
  data() {
    return {
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      testIds: {},
      testNames: [],
      usersList: [],
      marketerData: [],
      billingUsers: [],
      marketingManagerData: [],
      page: 1,
      pageSize: 25,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      search_string: "",
      emr: "",
      accession_id: "",
      patient_name: "",
      date_of_birth: "",
      hospital: "",
      test_performed_by: "",
      hospital_marketer: [],
      physician: "",
      searchBy: "",
      loading: false,
      orderBy: "created_at",
      orderType: "desc",
      caseType: "",
      test_info: [],
      caseTypes: caseConstants.CASE_TYPES_DATA,
      searchOptions: casesExtraSearchOptions,
      selectedCaseId: null,
      exportReportsDialogVisisble: false,
      hospitalsData: {},
      physiciansData: {},
      hospitalLoading: false,
      physicianLoading: false,
      userLoading: false,
      pregnant: "",
      errorMessage: false,
      messageData: "",
      testDetailsData: "",
      billing_assigne: "",
      Unbilled: ["Unbilled", "PENDING"],
      selectedCaseIds: [],
      filteredBillingUsers: [],
      searchQuery: "",
    };
  },

  mounted() {
    this.initData();
  },
  methods: {
    handleSelectionChange(rows) {
      if (rows) {
        this.selectedCaseIds = [];
        for (let caseData of rows) {
          this.selectedCaseIds.push(caseData._id);
        }
      } else {
        this.selectedCaseIds = [];
      }
    },
    filterUsers() {
      this.filteredBillingUsers = this.billingUsers.filter((user) =>
        this.getOptionLabel(user)
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      );
    },
    getOptionLabel(user) {
      // Check if the user is the current profile, return "Self"
      if (user._id === this.getAuthenticatedUser._id) {
        return "Self";
      }
      // Otherwise, return the user's first name and last name
      return user.first_name + " " + user.last_name;
    },

    getSelectedUser(billingId) {
      if (this.billingUsers && this.billingUsers.length) {
        const users = this.billingUsers.find(
          (billing) => billing._id === billingId
        );
        return users ? users.first_name + " " + users.last_name : null;
      }
      return null;
    },
    handleDropdownVisibleChange(visible) {
      if (!visible) {
        this.searchQuery = "";
        this.filteredBillingUsers = this.billingUsers; // Reset the filtered list
      }
    },
    async assignBillingUser(user) {
      try {
        this.loading = true;
        let params = {
          billing_representative: user.userId,
          case_ids: this.selectedCaseIds,
        };
        await this.$store.dispatch("cases/assignBulkRepresentative", params);
        if (this.getBulkBillingAssignStatus) {
          successNotification(
            "Billing representative assigned successfully for multiple accessions"
          );
          await this.fetchPendingBilledCases();
        } else {
          errorNotification("Error while assigning billing representative");
        }
        this.searchQuery = "";
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async handleBillingAssigne(payload) {
      try {
        this.loading = true;
        let params = {
          case_id: payload.rowId,
          billing_representative: payload.userId,
        };
        await this.$store.dispatch("cases/assignBillingRepresentative", params);
        if (this.getBillingAssignStatus) {
          successNotification("Billing Assigne Assigned successfully");
          await this.fetchPendingBilledCases();
        } else {
          errorNotification("Error while updating billing assigne");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async testReportsDownloadLog(report, testReports) {
      let params = {
        case_id: report._id,
        report_id: testReports._id,
      };
      await this.$store.dispatch("cases/testReportsDownloadLog", params);
    },
    async testReportsViewLog(report, testReports) {
      let params = {
        case_id: report._id,
        report_id: testReports._id,
      };
      await this.$store.dispatch("cases/testReportsViewLog", params);
    },
    async onReportsExport() {
      this.loading = true;

      this.exportReportsDialogVisisble = true;
      this.loading = false;
    },
    clearInputSearch() {
      if (
        this.hospital ||
        this.physician ||
        this.date_of_birth ||
        this.caseType ||
        this.test_info.length ||
        this.emr ||
        this.patient_name ||
        this.search_string ||
        this.accession_id ||
        this.test_performed_by ||
        this.hospital_marketer.length ||
        this.patient_name
      ) {
        this.hospital = "";
        this.test_performed_by = "";
        this.hospital_marketer = [];
        this.physician = "";
        this.date_of_birth = "";
        this.caseType = "";
        this.test_info = [];
        this.emr = "";
        this.patient_name = "";
        this.search_string = "";
        this.accession_id = "";
        this.patient_name = "";
        this.page = 1;
        this.fetchPendingBilledCases(1);
      }
    },
    async initData() {
      this.setQueryParamsData();
      this.loading = true;
      // if (this.getAuthenticatedUser && !this.$route.query.selectedBillingUser) {
      //   this.billing_assigne = this.getAuthenticatedUser._id;
      // }
      await Promise.all([this.fetchPendingBilledCases(), this.fetchAllUsers()]);
      this.filteredBillingUsers = this.billingUsers;
      this.loading = false;
    },

    setQueryParamsData() {
      this.page = this.$route.query.selectedPage
        ? parseInt(this.$route.query.selectedPage)
        : 1;
      this.pageSize = this.$route.query.selectedPageSize
        ? parseInt(this.$route.query.selectedPageSize)
        : 25;
      if (this.$route.query.selectedString) {
        this.search_string = this.$route.query.selectedString;
      }
      if (this.$route.query.selectedPatientName) {
        this.patient_name = this.$route.query.selectedPatientName;
      }
      if (this.$route.query.selectedEMR) {
        this.emr = this.$route.query.selectedEMR;
      }
      if (this.$route.query.selectedAccessionId) {
        this.accession_id = this.$route.query.selectedAccessionId;
      }
      if (this.$route.query.selectedCase) {
        this.caseType = this.$route.query.selectedCase;
        this.fetchAllTestPanels();
      }
      if (
        this.$route.query.selectedTest &&
        Array.isArray(this.$route.query.selectedTest) &&
        this.$route.query.selectedTest.length
      ) {
        this.fetchAllTestPanels();
        this.test_info = this.$route.query.selectedTest;
      } else if (
        this.$route.query.selectedTest &&
        this.$route.query.selectedTest.length
      ) {
        this.fetchAllTestPanels();
        this.test_info = [this.$route.query.selectedTest];
      }
      if (this.$route.query.selectedCaseId) {
        this.selectedCaseId = this.$route.query.selectedCaseId;
      }

      if (this.$route.query.selectedCaseOrderBy) {
        this.orderBy = this.$route.query.selectedCaseOrderBy;
      }
      if (this.$route.query.selectedCaseOrderType) {
        this.orderType = this.$route.query.selectedCaseOrderType;
      }
      if (this.$route.query.selectedPhysician) {
        this.fetchAllPhysicians();
        this.physician = this.$route.query.selectedPhysician;
      }
      if (this.$route.query.selectedHospital) {
        this.fetchAllHospitals();
        this.hospital = this.$route.query.selectedHospital;
      }
      if (this.$route.query.selectedUser) {
        this.fetchAllUsers();
        this.test_performed_by = this.$route.query.selectedUser;
      }
      if (this.$route.query.selectedMarketer) {
        this.fetchAllUsers();
        this.hospital_marketer = this.$route.query.selectedMarketer;
      }
      if (this.$route.query.selectedDateOfBirth) {
        this.date_of_birth = this.$route.query.selectedDateOfBirth;
      }
      if (this.$route.query.selectedBillingUser) {
        this.billing_assigne = this.$route.query.selectedBillingUser;
      }
      this.searchBy = this.$route.query.selectedSearchBy;
    },
    async fetchAllPhysicians() {
      this.physicianLoading = true;
      await this.$store.dispatch("physicians/fetchAllPhysicians", {
        get_all: true,
      });
      this.physiciansData = JSON.parse(JSON.stringify(this.getAllPhysicians));
      this.physicianLoading = false;
    },
    async fetchAllHospitals() {
      this.hospitalLoading = true;

      await this.$store.dispatch("hospitals/fetchAllHospitals", {
        get_all: true,
      });
      this.hospitalsData = JSON.parse(JSON.stringify(this.getAllHospitals));
      this.hospitalLoading = false;
    },
    async fetchAllUsers() {
      this.userLoading = true;

      await this.$store.dispatch("userAccounts/fetchAllUserAccounts", {
        get_all: true,
      });
      let usersForTestPerofmed = ["LAB_TECHNOLOGIST"];
      this.usersList = this.getAllUsers.data.filter((item) =>
        usersForTestPerofmed.includes(item.user_type)
      );
      let marketer = ["MARKETER"];
      this.marketerData = this.getAllUsers.data.filter((item) =>
        marketer.includes(item.user_type)
      );
      let billing = [
        "BILLING_REPRESENTATIVE",
        "AR_LEAD",
        "AR_EXECUTIVE",
        "CP_LEAD",
        "CODING_LEAD",
        "CODING_EXECUTIVE",
        "POSTING_EXECUTIVE",
        "SR_FTE",
      ];
      this.billingUsers = this.getAllUsers.data.filter((item) =>
        billing.includes(item.user_type)
      );

      this.userLoading = false;
    },
    prepareParams() {
      this.pregnant = "";
      const params = {
        page: this.page,
        limit: this.pageSize,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.emr) {
        params.emr = this.emr;
      }
      if (this.accession_id) {
        params.accession_id = this.accession_id;
      }
      if (this.patient_name) {
        params.patient_name = this.patient_name;
      }
      if (this.date_of_birth) {
        const date_of_birth = moment(this.date_of_birth).format("YYYY-MM-DD");

        params.date_of_birth = date_of_birth;
      }
      if (this.hospital) {
        params.hospital = this.hospital;
      }
      if (this.test_performed_by) {
        params.test_performed_by = this.test_performed_by;
      }
      if (this.hospital_marketer.length) {
        params.hospital_marketers = this.hospital_marketer;
      }
      if (this.caseType) {
        params.case_type = this.caseType;
      }
      if (this.test_info.length) {
        params.tests_info = this.test_info;
      }
      if (this.physician) {
        params.ordering_physician = this.physician;
      }
      if (this.searchBy == "Pregnancy") {
        this.pregnant = true;
        params.pregnant = true;
      }
      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      if (this.billing_assigne) {
        params.billing_representative = this.billing_assigne;
      }
      return params;
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "created_at";
        this.orderType = "desc";
      }
      this.fetchPendingBilledCases();
    },
    getAutoCompleteCases() {
      setTimeout(() => {
        this.page = 1;
        this.fetchPendingBilledCases();
      }, 600);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchPendingBilledCases();
    },
    clearTest() {
      this.caseType = "";
      this.test_info = [];
      this.fetchPendingBilledCases();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchPendingBilledCases();
    },
    handleCurrentChangeCaseType(page) {
      this.page = page;
      this.test_info = [];
      this.fetchAllTestPanels();
      this.fetchPendingBilledCases();
    },
    handleTest(page) {
      this.page = page;
      this.fetchPendingBilledCases();
    },
    async fetchAllTestPanels() {
      await this.$store.dispatch("labTests/fetchAllLabTests", {
        get_all: true,
        case_type_code: this.caseType,
      });
      if (this.getAllLabTests) {
        this.testNames = [];
        for (let caseData of this.getAllLabTests) {
          this.testNames.push(caseData);
        }
        this.testIds = {};
        let uniqueObject = {};
        if (this.caseType == "TOXICOLOGY") {
          let allTests = [];
          allTests = this.testNames.map((e) => {
            if (
              e.category_code == "SCREENING" ||
              e.category_code == "SCREENING_VALIDITY"
            ) {
              e.category = "SCREENING";
            } else if (e.category_code == "ETG/ETS") {
              e.category = "ETG/ETS";
            } else {
              e.category = "CONFIRMATION";
            }
            return e;
          });
          for (let i = 0; i < allTests.length; i++) {
            let objTitle = allTests[i].category;
            uniqueObject[objTitle] = allTests[i];
          }
          this.testIds = uniqueObject;
        } else {
          for (let i = 0; i < this.testNames.length; i++) {
            let objTitle = this.testNames[i].category;
            uniqueObject[objTitle] = this.testNames[i];
          }
          this.testIds = uniqueObject;
        }
      } else {
        this.testNames = [];
        this.testIds = {};
      }
    },

    async fetchPendingBilledCases() {
      try {
        let params = this.prepareParams();
        this.loading = true;
        await this.$store.dispatch("cases/fetchAllBilledCases", params);
        this.updateQueryParams(params);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    goToCaseView(caseId) {
      if (this.$route.query.selected_panel) {
        this.$route.query.selected_panel = undefined;
      }
      this.$router.push({
        name: "CaseView",
        params: { case_id: caseId },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    onViewReports(report) {
      let testReportsData = report.test_reports;

      for (let i = 0; i < testReportsData.length; i++) {
        if (
          (testReportsData[i].is_manually ||
            testReportsData[i].additional_report_merged ||
            testReportsData[i].additional_report_already_merged) &&
          testReportsData[i].mark_for_physician
        ) {
          let routerData = this.$router.resolve({
            name: "supplementaryReportView",
            params: {
              report_id: testReportsData[i]._id,
              case_id: report._id,
            },
          });
          window.open(routerData.href, "_blank");
        } else {
          if (testReportsData[i].mark_for_physician) {
            let routerData = this.$router.resolve({
              name: "ResultFileTestReports",
              params: {
                report_id: testReportsData[i]._id,
                case_id: report._id,
              },
            });
            window.open(routerData.href, "_blank");
          }
        }
        this.testReportsViewLog(report, testReportsData[i]);
        this.testReportsDownloadLog(report, testReportsData[i]);
        window.location.reload();
      }
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedPage: params.page,
        selectedCase: params.case_type,
        selectedTest: params.tests_info,
        selectedHospital: params.hospital,
        selectedUser: params.test_performed_by,
        selectedMarketer: params.hospital_marketers,
        selectedDateOfBirth: params.date_of_birth,
        selectedPhysician: params.ordering_physician,
        selectedString: params.search_string,
        selectedPatientName: params.patient_name,
        selectedEMR: params.emr,
        selectedAccessionId: params.accession_id,
        selectedPageSize: params.limit,
        selectedCaseId: this.$route.query.selectedCaseId,
        selected_panel: this.$route.query.selected_panel,
        selectedSearchBy: this.searchBy || undefined,
        selectedCaseOrderBy: params.order_by,
        selectedCaseOrderType: params.order_type,
        selectedBillingUser: params.billing_representative,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
  },
};
</script>

<style lang="scss">
.test-reports {
  display: flex;
  align-items: center;
  padding: 4px 5px;
  .regular-reports {
    &.single-report {
      margin-left: 20px;
    }
    h5 {
      color: #0a5da6;
      font-size: 14px;
      span {
        color: #bdbdbd;
      }
    }
  }
  .repots {
    h5 {
      color: #0a5da6;
    }
  }
}

.test-tabs {
  padding: 0;

  .el-tabs__active-bar {
    height: 3px;
    background-color: #0a5da6;
    border-radius: 25px;
  }
  .el-tabs__item.is-active {
    color: #0a5da6;
    font-weight: 600;
  }

  .el-tabs__item {
    color: #828282;
  }
  .el-tabs__nav-scroll {
    padding: 0 18px;
  }

  .types {
    padding: 0 5px;
    .case-type {
      &.view-reports-dialog {
        .el-button {
          width: 100%;
          min-height: 36px;
          padding: 0 17px;
          background-color: #f2f6f9;
          border: 1px dashed #bababc;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              margin-right: 6px;
            }
            p {
              margin-left: 8px;
              color: #828282;
            }
          }
        }
      }
      .el-input__inner {
        height: 36px;
        border: 1px solid rgba(86, 204, 242, 0.8);
      }
    }
    .btn {
      margin-top: 23px;
      .el-button {
        min-height: 36px;
        padding: 8px 24px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 7px;
      }
    }
  }
  .mark {
    margin-left: 15px;
  }
  .panel {
    .documents {
      display: none;
    }
    .el-table__header {
      color: rgba(0, 0, 0, 0.8);
      // background: #e5e5e5;
      border-spacing: 0 5.5px;
      border-radius: 25px;
    }
    .el-table {
      background: rgb(238, 242, 245);
      .el-table__cell {
        padding: 5px 0;
      }
      .el-table__header-wrapper {
        border-radius: 10px;
      }
      .el-table__body-wrapper {
        border-radius: 6px;
      }
      .el-table__cell {
        .cell {
          font-weight: 500;
        }
      }
    }
    .el-table thead {
      color: rgba(0, 0, 0, 0.8);
    }
    .physician-btn {
      .el-switch .el-switch__core {
        width: 34px !important;
        height: 15px;
        background-color: #0a5da6;
        border: none;
        .el-switch__action {
          top: 3px;
          left: 2px;
          width: 9px;
          height: 9px;
        }
      }
      .el-switch__label.is-active {
        color: #0a5da6;
      }
      .el-switch.is-checked {
        .el-switch__core {
          width: 34px !important;
          height: 15px;
          background-color: #4bb543 !important;
          border: none;
        }
        .el-switch__action {
          top: 2.2px;
          left: 38.5px;
          width: 10px;
          height: 10px;
        }
      }
    }
    .title {
      color: #2f80ed;
      img {
        color: #eb5757;
        vertical-align: middle;
      }
    }
  }
  .action-test-icons {
    display: flex;
    li {
      margin-right: 10px;
      .el-button {
        min-height: auto;
        padding: 0;
        background: none;
        border: none;
        transition: all 0.2s;

        &:hover {
          background-color: #eef2f5;
        }
        &:active {
          background-color: #eef2f5;
        }
        &:focus {
          background-color: #eef2f5;
        }
        img {
          color: red;
          &:hover {
            color: #0a5da6;
          }
        }
      }
    }
  }
}
.documents {
  padding: 0 20px;
  .el-tabs__item.is-active {
    color: #0a5da6;
    font-weight: 500;
  }
  .el-tabs__active-bar {
    background-color: #0a5da6;
    border-radius: 25px;
  }
  .document-container {
    display: grid;
    align-items: center;

    gap: 10px;
    grid-template-columns: 230px 230px;
  }
  .document-cell {
    display: grid;
    align-items: center;
    padding: 0 0.3rem;
    background-color: #fff;
    border: 1px solid #56ccf2;
    border-radius: 7px;

    grid-template-columns: 9% 75% 8% 10%;
    img {
      width: 18px;
      height: 18px;
    }
    .cancel {
      width: 15px;
      height: 15px;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
    }
    .demo-progress {
      .el-progress--line {
        width: 230px;
        .el-progress-bar__outer {
          height: 5px !important;
          background-color: #9ae0f7;
        }
        .el-progress-bar__inner {
          height: 5px;
          background-color: #2d9cdb;
        }
      }
      .el-progress__text {
        color: #828282;
      }
    }
  }
  .document-cell {
    display: grid;
    align-items: center;
    padding: 0 0.3rem;
    background-color: #fff;
    border: 1px solid #56ccf2;
    border-radius: 7px;

    grid-template-columns: 9% 80% 8% 10%;
    img {
      width: 18px;
      height: 18px;
    }
    .cancel {
      width: 15px;
      height: 15px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.listings-navbar {
  .barcode-print {
    .el-button--small {
      padding: 7px 15px;
      border-radius: 8px;
      &:hover {
        color: #4f4f4f;
        background: #fff;
        box-shadow: none;
        border: 1px solid #dedbdb;
      }
      &:focus {
        color: #4f4f4f;
        background: #fff;
        box-shadow: none;
        border: 1px solid #dedbdb;
      }
      &:active {
        color: #4f4f4f;
        background: #fff;
        box-shadow: none;
        border: 1px solid #dedbdb;
      }
    }
    .bulk-assign-btn {
      padding: 10px;
      color: #273480;
      font-weight: 500;
      text-transform: uppercase;
      background: #ffffff;
      border: 1px solid #dedbdb;
      border-radius: 6.4px;
      transition: all 0.2s ease-in-out;
      &:hover {
        background: #273480;
        color: #fff;
      }
    }
  }
}
.btn-synced {
  min-width: 100px;
  height: auto;
  min-height: 20px;
  padding: 6px 5px;
  color: #4bb543;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1;
  letter-spacing: 0 !important;
  text-align: center;
  border: 0.6px solid #4bb543;
  border-radius: 4px !important;
  &:hover,
  &:active,
  &:focus {
    background: #f2f2f2;
    border: 0.6px solid #4bb543;
    color: #4bb543;
  }
}
.btn-sync {
  min-width: 100px;
  height: auto;
  min-height: 20px;
  padding: 6px 5px;
  color: #5162fd;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1;
  letter-spacing: 0 !important;
  text-align: center;
  border: 0.6px solid #5162fd;
  border-radius: 4px !important;
  &:hover,
  &:active,
  &:focus {
    background: #f2f2f2;
    border: 0.6px solid #5162fd;
    color: #5162fd;
  }
}
.btn-processing {
  min-width: 100px;
  height: auto;
  min-height: 20px;
  padding: 6px 5px;
  color: #f28b0c;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1;
  letter-spacing: 0 !important;
  text-align: center;
  border: 0.6px solid #f28b0c;
  border-radius: 4px !important;
  &:hover,
  &:active,
  &:focus {
    background: #f2f2f2;
    border: 0.6px solid #f28b0c;
    color: #f28b0c;
  }
}
</style>
